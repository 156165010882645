import Home from "../views/Home";
import QuestionPage from "../views/QuestionPage";

let routes = [
    // {
    //     path: "/tvonderzoek",
    //     component: Home,
    //     layout: "main",
    // },
    // {
    //     path: "/tvonderzoek2",
    //     component: Home,
    //     layout: "main",
    // },
    // {
    //     path: "/questionPage",
    //     component: QuestionPage,
    //     layout: "main",
    // },
];
export default routes;
